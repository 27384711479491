import { Directive, EventEmitter, inject, OnDestroy, Output } from '@angular/core';
import { FeatureItemResponse } from '@iqModels/Maps/FeatureItemResponse.model';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import * as _ from 'lodash';
import { MapTileSearchRequest } from 'Models/Searching/MapTileSearchRequest.model';
import { SearchFilter } from 'Models/Searching/SearchFilter.model';
import { SearchRequest } from 'Models/Searching/SearchRequest.model';
import { TicketMapItem } from 'Models/Tickets/TicketMapItem.model';
import { Collection, Map, MapBrowserEvent, Overlay } from 'ol';
import { Control } from 'ol/control';
import { Coordinate } from 'ol/coordinate';
import { EventsKey } from 'ol/events';
import { containsExtent, Extent } from 'ol/extent';
import { FeatureLike } from 'ol/Feature';
import { Interaction } from 'ol/interaction';
import { unByKey } from 'ol/Observable';
import { Pixel } from 'ol/pixel';
import { TicketsLayer } from 'Pages/Tickets/TicketMapViewer/Layers/TicketsLayer';
import { map, Observable, of } from 'rxjs';
import { CommonService } from 'Services/CommonService';
import { MapSearchService } from 'Services/MapSearchService';
import { ClickSelectInteraction } from 'Shared/Components/Maps/Interactions/ClickSelectInteraction';
import { DragBoxSelectInteraction } from 'Shared/Components/Maps/Interactions/DragBoxSelectInteraction';
import { MapBaseComponent } from 'Shared/Components/Maps/MapBase.component';
import { TicketSearchQueryConfiguration } from '../Search/Models/TicketSearchQueryConfiguration';
import { IMapViewerService } from './Services/IMapViewerService.interface';

/**
 *  Base class for a map that shows Tickets or TicketResponses.  Can be either a standalone map or shown next to a Ticket Search list.
 */
@Directive()
export abstract class BaseTicketMapViewerComponent extends MapBaseComponent implements OnDestroy {

    @Output()
    public TicketsSelected = new EventEmitter<string[]>();

    private _Config: TicketSearchQueryConfiguration;

    public get CurrentStateAbbreviation(): string { return null; }
    public get CurrentCountyName(): string { return null; }
    public get MapSearchFilterBounds(): Extent { return null; }

    private _TicketsLayer: TicketsLayer;
    private _LastTicketExtents: Extent;

    private _ClickSelectInteraction: ClickSelectInteraction;
    private _DragBoxSelectInteraction: DragBoxSelectInteraction;

    //  ID is either a Ticket.ID or a TicketResponse.ID depending on the type of map we are displaying.
    //  The name of the property used is specified by MapCachKeyPropertyName - this is what is returned in the Tile request features.
    private _CachedMapItems: { [id: string]: TicketMapItem } = {};

    //  Unique ID used for the MapItemCache.  Normally, this is "ID" (which holds a Ticket.ID).  But for the TicketResponse map,
    //  this needs to be TicketResponseID.
    protected get MapCachKeyPropertyName(): string { return "ID"; }

    private _PointerMoveEventsKey: EventsKey;

    private _MapTileSearchRequest: MapTileSearchRequest;

    public LimitToTicketsWithinXFtOfCurrentLocation?: number;

    private _PopupOverlay: Overlay;
    private _TicketDetailsOverlayControl: Control;

    public SelectedTicketList: TicketMapItem[] = [];

    private _LastFilters: SearchFilter[] = null;

    constructor(commonService: CommonService, private _MapViewerService: IMapViewerService) {
        super(commonService, inject(MapSearchService));
    }

    public ngOnDestroy(): void {
        if (this._PopupOverlay) {
            this.Map.removeOverlay(this._PopupOverlay);
            this._PopupOverlay = null;
        }

        if (this._TicketDetailsOverlayControl) {
            this.Map.removeControl(this._TicketDetailsOverlayControl);
            this._TicketDetailsOverlayControl = null;
        }

        if (this._PointerMoveEventsKey) {
            unByKey(this._PointerMoveEventsKey);
            this._PointerMoveEventsKey = null;
        }

        super.ngOnDestroy();
    }

    private InitConfig(): void {
        const searchRequest = new SearchRequest();

        searchRequest.Filters = [];
        if (this._Config.ViewFilters)
            searchRequest.Filters = this._Config.ViewFilters;
        if (this._Config.DefaultFilters)
            searchRequest.Filters = searchRequest.Filters.concat(this._Config.DefaultFilters);

        this._MapTileSearchRequest = new MapTileSearchRequest(searchRequest);

        if (this._TicketsLayer)
            this._TicketsLayer.MapTileSearchRequest = this._MapTileSearchRequest;

        //  If there is a filter limiting to the current location, set this to force zooming to current location and hide the "Tickets" link on the .html page.
        const withinFeetValues = this._Config.DefaultFilters?.find(f => f.Operator === SearchFilterOperatorEnum.WithinXFeetOfLocation)?.Values;
        if (withinFeetValues && withinFeetValues.length === 3)
            this.LimitToTicketsWithinXFtOfCurrentLocation = withinFeetValues[2].FilterValue;
    }

    protected override BuildInteractions(): Collection<Interaction> {
        //  interaction.defaults: https://openlayers.org/en/latest/apidoc/module-ol_interaction.html
        const interactions = super.BuildInteractions();

        this._ClickSelectInteraction = new ClickSelectInteraction((featureList, toggleSelected) => this.OnFeaturesSelected(featureList, toggleSelected));
        interactions.push(this._ClickSelectInteraction);

        this._DragBoxSelectInteraction = new DragBoxSelectInteraction((featureList) => this.OnFeaturesSelected(featureList, false));
        interactions.push(this._DragBoxSelectInteraction);

        return interactions;
    }

    public ClearFilters(): void {
        this._LastFilters = null;
    }

    public SetFilters(filters: SearchFilter[], textValueFilter: SearchFilter): void {
        //console.warn("SetFilters", filters, this.FiltersChanged(filters));

        //  Merge the filters and textValueFilter into one array.  Don't know why that had to be separate.  textValueFilter has the ticket number filter in it.
        //  Must clone because changing a filter will modify them in place.
        let mergedFilters = [];
        mergedFilters = mergedFilters.concat(_.cloneDeep(filters));
        if (textValueFilter)
            mergedFilters.push(_.cloneDeep(textValueFilter));

        if ((mergedFilters.length === 0) || !this.FiltersChanged(mergedFilters))
            return;

        const config = new TicketSearchQueryConfiguration();
        config.ViewFilters = mergedFilters;
        this._LastFilters = mergedFilters;

        this.SetTicketSearchQueryConfiguration(config);
    }

    private FiltersChanged(filters: SearchFilter[]): boolean {
        if (!this._LastFilters || filters.length !== this._LastFilters.length)
            return true;

        //  To compare, JSON.stringify is used to compare the objects as the objects are not the same instance.
        //  This won't work correctly if the objects are the same but in different orders.  Or if the filters have an array of values
        //  which also only change order.  But the filters should be constructed the same way all the time.
        //console.warn("FiltersChanged: filters", JSON.stringify(filters));
        //console.warn("FiltersChanged: _LastFilters", JSON.stringify(this._LastFilters));
        return (JSON.stringify(filters) !== JSON.stringify(this._LastFilters));
    }

    /**
     * Sets the filters used for the ticket map tiles then immediately zooms the map to the extents of the tickets covered by the filters.
     * @param config
     */
    public SetTicketSearchQueryConfiguration(config: TicketSearchQueryConfiguration): void {
        this._Config = config;
        this.InitConfig();

        this.Clear();

        const lastZoomMethod = localStorage.getItem("ticketDashboardZoomMethod");
        if (lastZoomMethod === "tickets" && !this.LimitToTicketsWithinXFtOfCurrentLocation)
            this.ZoomToTicketExtents();
        else
            this.PositionToCurrentLocation();       //  Defaults to location if not set
    }

    public PositionToCurrentLocation(): void {
        this.SetLastZoomMethod("location");
        super.PositionToCurrentLocation();
    }

    protected HandleCurrentPositionError(error: GeolocationPositionError): void {
        this.ZoomToTicketExtents();
    }

    protected HandleCurrentPositionNotInMapBounds(): void {
        //  Default is to zoom to full extents - try to zoom to tickets first
        this.ZoomToTicketExtents();
    }

    public ZoomToTicketExtents(): void {
        //  Don't set this if we zoom to ticket extents - that will happen if the bounds are outside the One Call.  Do not want to change the default in that case.
        if (!this.LimitToTicketsWithinXFtOfCurrentLocation)
            this.SetLastZoomMethod("tickets");

        this._MapViewerService.Extents(this._MapTileSearchRequest).subscribe({
            next: extents => {
                //  Same these extents so that we can use CurrentZoomExtentsCoversTicketExtents() to check to see if the current extents covers the
                //  ticket extents.  Allows us to avoid adding a DigSiteBounds filter on the ticket list (and sometimes avoid an extra search)
                //  when it is not necessary.
                this._LastTicketExtents = [extents.MinX, extents.MinY, extents.MaxX, extents.MaxY] as Extent;

                this.ZoomToLatLonBounds(extents, 80);       //  80 matches the default on ZoomToExtent (which is used for zoom to dig site bounds)
            },
            error: () => {
                //  Happens if there are no tickets in the search results.  Just zoom to full extents.
                this._LastTicketExtents = null;
                this.ZoomToFullMapExtents();
            }
        });
    }

    //  This attempts to zoom to the extents of the currently selected tickets.
    //  Which works but not very well if the map is zoomed such that pins are being shown.  And we may not have
    //  the features loaded in the map tiles to begin with since the number of features per tile is capped.
    //  May come back to this later but left this code here in case it's needed.
    //  I think to make this work well, we may need to return the bounds of the item in the TicketMapItem.
    //  ** But then we will probably have issues where someone will select-all on a list of millions of rows and then try to do this.
    //public ZoomToSelectedTicket(): void {
    //    const featureList = this._TicketsLayer.Layer.getSource().getFeaturesInExtent(this.Map.getView().calculateExtent(this.Map.getSize()));

    //    referenced functions need this import: import { boundingExtent, containsExtent, createEmpty, extend, Extent } from 'ol/extent';
    //    const extent = featureList
    //        .filter(feature => {
    //            const id = feature.getProperties()[this.MapCachKeyPropertyName];
    //            return this._TicketsLayer.SelectedTicketIDList.includes(id);
    //        })
    //        .map(feature => feature.getGeometry().getExtent())
    //        .reduce((accumulator: Extent, currentValue: Extent) => extend(accumulator, currentValue), createEmpty());

    //    this.ZoomToExtent(extent);
    //}

    public CurrentZoomExtentsCoversTicketExtents(): boolean {
        if (!this._LastTicketExtents)
            return true;        //  No tickets in search query so return true (will prevent the ticket list from re-querying using the full map extents)

        const currentExtents = this.CurrentViewExtents();
        return containsExtent(currentExtents, this._LastTicketExtents);
    }

    public SetLastZoomMethod(method: "tickets" | "location"): void {
        try {
            localStorage.setItem("ticketDashboardZoomMethod", method);
        } catch { /* empty */ }
    }

    public Clear(): void {
        this._CachedMapItems = {};

        const source = this._TicketsLayer?.Layer?.getSource();
        if (source)
            source.clear();     //  This will also refresh the map

        this.HideTicketDetails();
    }

    protected OnMapInitialized(map: Map): boolean {
        this._TicketsLayer = new TicketsLayer(map, this._MapViewerService.TileUrl(), this.CommonService.AuthenticationService, this._MapTileSearchRequest, this.MapCachKeyPropertyName);
        this._ClickSelectInteraction.SetSource(this._TicketsLayer.Layer.getSource());
        this._DragBoxSelectInteraction.SetSource(this._TicketsLayer.Layer.getSource());

        this._PointerMoveEventsKey = map.on('pointermove', evt => this.OnPointerMove(evt));

        //  Create a custom Component overlay to show the ticket details after clicking on a ticket.
        //  Custom OpenLayers Overlay: https://openlayers.org/en/latest/examples/popup.html
        //  The content comes from the "popup" element in the .html - which is then dynamic since it's managed by Angular.
        const container = document.getElementById('popup');
        const closer = document.getElementById('popup-closer');
        this._TicketDetailsOverlayControl = new Control({
            element: container,
        });
        closer.onclick = () => {
            this.HideTicketDetails();
            closer.blur();
            return false;
        };
        this.Map.addControl(this._TicketDetailsOverlayControl);
        this.HideTicketDetails();

        return true;            //  Tells base to not position to default extents
    }

    private HideTicketDetails(): void {
        const popupElement = document.getElementById('popup');
        if (popupElement?.classList)
            popupElement.classList.add("hidden");
        if (this._TicketsLayer)
            this._TicketsLayer.MapItemPagerVisibleTicketID = null;
    }

    private ShowTicketDetails(): void {
        const popupElement = document.getElementById('popup');
        if (popupElement?.classList)
            popupElement.classList.remove("hidden");
    }

    protected GetBestFitExtents(): Extent {
        return null;
    }

    protected OnPointerMove(evt: MapBrowserEvent<any>): void {
        //  Only do this on desktop.  On mobile, the "onpointermoved" event does not apply since it's not possible to hover over a feature.
        //  But the event is triggered by clicking and dragging.
        if (this.CommonService.DeviceDetectorService.IsDesktop) {
            if (this._TicketsLayer)
                this._TicketsLayer.HoveringOverTicketID = this.FindTicketIDAtPixel(evt.pixel);
        }
    }

    private OnFeaturesSelected(featureList: FeatureLike[], toggleSelected: boolean): void {
        //console.warn("OnFeaturesSelected", featureList, this);
        this.GetMapItemsForFeatures(featureList).subscribe(mapItems => this.SelectMapItems(mapItems, toggleSelected));
    }

    private SelectMapItems(mapItems: TicketMapItem[], toggleSelected: boolean): void {
        if (toggleSelected) {
            //  If Control Key is pressed when clicking, we append new items and remove items that are picked that are
            //  already in the list (so they can be toggled in and out of the list).
            if (!this.SelectedTicketList)
                this.SelectedTicketList = [];
            if (mapItems) {
                mapItems.forEach(i => {
                    const idx = this.SelectedTicketList.findIndex(t => t.ID === i.ID);
                    if (idx >= 0)
                        this.SelectedTicketList.splice(idx, 1);
                    else
                        this.SelectedTicketList.push(i);
                });
            }
        } else
            this.SelectedTicketList = mapItems ?? [];

        if (this.SelectedTicketList.length === 0)
            this.HideTicketDetails();
        else
            this.ShowTicketDetails();

        //  ID is either Ticket.ID or TicketResponse.ID depending on the type of map we are displaying.
        const selectedFeatureIDList = this.SelectedTicketList.map(t => t.ID);
        //console.warn("SelectMapItems: mapItems = ", selectedTicketIDList);
        this.HighlightedSelectedTicketsOnMap(selectedFeatureIDList);

        this.TicketsSelected.next(selectedFeatureIDList);
    }

    //  This is called when the context menu is displayed if IsContextMenuDirty is set to true.
    //  So can rebuild the menu by setting that property.
    protected override BuildContextMenuItems(event: { type: string, pixel: Pixel, coordinate: Coordinate }): any[] {
        const contextMenuItems = super.BuildContextMenuItems(event);

        //  Find all of the tickets at the location of the event.  If we have any, add context menu items to view them.
        //  If we do not have TicketMapItems for one or more tickets, we have to fetch that asynchronously.  So in that case, we will re-trigger the
        //  context menu build when that api request completes.
        const cachedItems = this.GetCachedMapItemsAtPixel(event.pixel);

        if (cachedItems.missingIDs.length > 0) {
            //  Need to fetch the info for the missing tickets
            this.FetchMapItems(cachedItems.missingIDs, cachedItems.mapItems).subscribe(() => this.RebuildContextMenu(event));
        }

        if (cachedItems.mapItems.length > 0) {
            if (contextMenuItems.length > 0)
                contextMenuItems.push("-");     //  Separator

            let count = 0;
            cachedItems.mapItems
                .sort((a, b) => (a.TicketNumber + "-" + a.Version).toLocaleLowerCase().localeCompare((b.TicketNumber + "-" + b.Version).toLocaleLowerCase()))
                .forEach(mi => {
                    count++;
                    if (count <= 5) {
                        contextMenuItems.push({
                            text: "<i class='fas fa-up-right-from-square' ></i>View Ticket " + mi.TicketNumber + "-" + mi.Version,
                            classname: "iq-image-item",
                            callback: () => window.open("/tickets/view/" + (mi.TicketID ?? mi.ID), '_blank').focus()
                        });
                    }
                });
        }

        return contextMenuItems;
    }

    private FindTicketIDAtPixel(pixel: Coordinate): string {
        //  Find the ID (or one of them) that is being hovered over.  Set that ID into the TicketsLayer.
        //  If it changes, it will trigger a change on the layer to redraw it.  Which will cause any features
        //  with the same ID to be highlighted.  This works better for a MVT tile layer because the features may
        //  be clipped randomly.  So this will re-style ALL of the features for that ID no matter how they were clipped.
        let hoveringOverTicketID: string = null;
        let keepCurrentID: boolean = false;

        this.Map.forEachFeatureAtPixel(pixel, (feature/*, layer*/) => {
            const id = feature.getProperties()[this.MapCachKeyPropertyName];

            //  If we find the currently selected ID, keep it.  This keeps things from flashing when moving the mouse
            //  slightly when the features are chopped and overlapping with another dig site.
            if (id === this._TicketsLayer?.HoveringOverTicketID) {
                keepCurrentID = true;
                return true;        //  This tells forEachFeatureAtPixel to stop iterating
            }
            else
                hoveringOverTicketID = id;
        }, { hitTolerance: 5 });

        return keepCurrentID ? this._TicketsLayer?.HoveringOverTicketID : hoveringOverTicketID;
    }

    public GetAdditionalMapFeaturesForPopup(pixel: Coordinate, selectionBox: Extent): Observable<{ Features: FeatureItemResponse[], Exclusive: boolean }> {
        return this.GetAllMapItemsAtPixel(pixel).pipe(map(mapItems => {
            if (!mapItems || (mapItems.length === 0))
                return { Features: [], Exclusive: false };
            else {
                const totalItems = mapItems.length;

                mapItems = _.sortBy(mapItems, m => m.SortByDate);
                mapItems = _.take(mapItems, 5);
                const featureItems = mapItems
                    .map(m => {
                        let featureName = m.TicketNumber + ' v' + m.Version;
                        if (m.Tooltip)
                            featureName += ", " + m.Tooltip;
                        return new FeatureItemResponse("Ticket", featureName, null);
                    });

                if (totalItems > 5)
                    featureItems.push(new FeatureItemResponse("Ticket", "...plus " + (totalItems - 5) + " more tickets", null));

                return { Features: featureItems, Exclusive: false };
            }
        }));
    }

    /**
     * Returns all TicketMapItems for the tickets at the specified pixel.  If any are not cached, they will be fetched.
     */
    private GetAllMapItemsAtPixel(pixel: Coordinate): Observable<TicketMapItem[]> {
        const cachedItems = this.GetCachedMapItemsAtPixel(pixel);

        if (cachedItems.missingIDs.length === 0)
            return of(cachedItems.mapItems);        //  All tickets are already cached (or there aren't any at all)

        //  Need to fetch the info for the missing tickets.  This returns the new items added to the cachedItems we already found.
        return this.FetchMapItems(cachedItems.missingIDs, cachedItems.mapItems);
    }

    private GetMapItemsForFeatures(featureList: FeatureLike[]): Observable<TicketMapItem[]> {
        const cachedItems = this.GetCachedMapItemsForFeatures(featureList);

        if (cachedItems.missingIDs.length === 0)
            return of(cachedItems.mapItems);        //  All tickets are already cached (or there aren't any at all)

        //  Need to fetch the info for the missing tickets.  This returns the new items added to the cachedItems we already found.
        return this.FetchMapItems(cachedItems.missingIDs, cachedItems.mapItems);
    }

    /**
     *  Returns the already cached TicketMapItems for the tickets at the specified pixel.  Also returns the IDs of any tickets that are not cached.
     */
    private GetCachedMapItemsAtPixel(pixel: Coordinate): { mapItems: TicketMapItem[], missingIDs: string[] } {
        const featureList = this.Map.getFeaturesAtPixel(pixel, {
            hitTolerance: 5,
            layerFilter: (layerCandidate) => layerCandidate === this._TicketsLayer?.Layer
        });

        return this.GetCachedMapItemsForFeatures(featureList);
    }

    private GetCachedMapItemsForFeatures(featureList: FeatureLike[]): { mapItems: TicketMapItem[], missingIDs: string[] } {
        const mapItems: TicketMapItem[] = [];
        const missingIDs: string[] = [];

        featureList.forEach(feature => {
            const id = feature.getProperties()[this.MapCachKeyPropertyName];
            if (id) {
                const item = this._CachedMapItems[id];
                if (item && (mapItems.indexOf(item) === -1))
                    mapItems.push(item);
                else if (!item && (missingIDs.indexOf(id) === -1))
                    missingIDs.push(id);
            }
        });

        return { mapItems, missingIDs };
    }

    private FetchMapItems(idList: string[], mapItems: TicketMapItem[]): Observable<TicketMapItem[]> {
        return this._MapViewerService.MapItems(idList).pipe(
            map(fetchedItems => {
                if (fetchedItems) {
                    fetchedItems.forEach(mi => {
                        this._CachedMapItems[mi.ID] = mi;
                        mapItems.push(mi);
                    });
                }
                return mapItems;
            }));
    }

    public MapItemPagerVisibleTicket(ticketID: string): void {
        if (this._TicketsLayer)
            this._TicketsLayer.MapItemPagerVisibleTicketID = ticketID;
    }

    //  IDs are either Ticket.ID or TicketResponse.ID depending on the type of map we are displaying.
    public HighlightedSelectedTicketsOnMap(idList: string[]): void {
        if (this._TicketsLayer)
            this._TicketsLayer.SelectedFeatureIDList = idList;
    }
}
